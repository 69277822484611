import { mapHelper, generateMapByOpts } from 'common/utils'

// 状态
const status = [
  {
    text: '正常',
    value: 1
  },
  {
    text: '关闭',
    value: 0
  }
]

const {
  map: statusMap,
  setOps: statusOps
} = mapHelper.setMap(status)

// 跳转类型
const skipType = [
  {
    text: '至详情页',
    value: 1
  },
  {
    text: '至功能页',
    value: 2
  },
  {
    text: '至外部链接',
    value: 3
  }
]

const {
  map: skipTypeMap,
  setOps: skipTypeOps
} = mapHelper.setMap(skipType)

// 所属应用
const topicType = [
  {
    text: '议事堂',
    value: 4
  },
  {
    text: '组局活动',
    value: 2
  },
  {
    text: '红黑榜',
    value: 9
  },
  {
    text: '园区互助',
    value: 1
  },
  {
    text: '车位库房',
    value: 3
  },
  {
    text: '爱分享',
    value: 6
  }
]

const {
  map: topicTypeMap,
  setOps: topicTypeOps
} = mapHelper.setMap(topicType)

// 帖子状态
const topicStatus = [
  {
    text: '正常',
    value: 1
  },
  {
    text: '关闭',
    value: 3
  },
  {
    text: '举报中',
    value: 5
  }
]

const {
  map: topicStatusMap,
  setOps: topicStatusOps
} = mapHelper.setMap(topicStatus)

// 帖子是否置顶
const isTop = [
  {
    text: '是',
    value: 1
  },
  {
    text: '否',
    value: 0
  }
]

const {
  map: isTopMap,
  setOps: isTopOps
} = mapHelper.setMap(isTop)

// 列表展示的所属应用
const showTopicType = [
  {
    text: '园区互助',
    value: 1
  },
  {
    text: '组局活动',
    value: 2
  },
  {
    text: '车位库房',
    value: 3
  },
  {
    text: '议事堂',
    value: 4
  },
  {
    text: '悬赏',
    value: 5
  },
  {
    text: '爱分享',
    value: 6
  },
  {
    text: '工作室',
    value: 7
  },
  {
    text: '社群',
    value: 8
  },
  {
    text: '红黑榜',
    value: 9
  },
  {
    text: '红黑榜',
    value: 10
  }
]

const {
  map: showTopicTypeMap
} = mapHelper.setMap(showTopicType)

// 分类层级
const codeLevel = [
  {
    text: '一级分类',
    value: 1
  },
  {
    text: '二级分类',
    value: 2
  }
]

const {
  map: codeLevelMap,
  setOps: codeLevelOps
} = mapHelper.setMap(codeLevel)

const communityStatus = [
  {
    text: '正常',
    value: 0
  }, {
    text: '关闭',
    value: 1
  }
]
const {
  map: communityStatusMap,
  setOps: communityStatusOps
} = mapHelper.setMap(communityStatus)

// searchRegionType
const searchRegionType = [
  {
    text: '公司',
    value: 0
  },
  // {
  //   text: '园区公司分公司',
  //   value: 1
  // },
  {
    text: '管理项目组',
    value: 2
  }
]

const {
  map: searchRegionTypeMap,
  setOps: searchRegionTypeOps
} = mapHelper.setMap(searchRegionType)

// 所属APP
const appType = [
  {
    text: '用户端App',
    value: 0
  },
  {
    text: '管理端App',
    value: 1
  }
]

// 层级
const hierarchyOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '平台',
    value: 1
  },
  {
    text: '公司',
    value: 2
  },
]

const hierarchyMap = generateMapByOpts(hierarchyOps)

const {
  map: appTypeMap,
  setOps: appTypeOps
} = mapHelper.setMap(appType)

// jump-go校验参数映射
const jumpGoCodeMap = {
  storeCode: 'storeIdList', // 门店
  productCode: 'productIdList', // 商品
  secKillCode: 'secKillIdList', // 秒杀
  discountTopicCode: 'discountTopicId', // 促销专题
  knowledgeInfoCode: 'knowledgeInfoIdList', // 资讯
  postsCode: 'tableIdList', // 帖子
  groupCode: 'groupIdList', // 社群
  socialActivityCode: 'socialActivityIdList', // 社区活动
  surveyCode: 'questionIdList', // 问卷调查
  selfRuleCode: 'selfRuleIdList', // 自治规约
  autonomyVoteCode: 'questionNaireIdList', // 自治表决
  workRoomCode: 'workRoomIdList' // 社区工作室
}

export {
  statusMap,
  statusOps,
  skipTypeMap,
  skipTypeOps,
  topicTypeMap,
  topicTypeOps,
  topicStatusMap,
  topicStatusOps,
  isTopMap,
  isTopOps,
  showTopicTypeMap,
  codeLevelMap,
  codeLevelOps,
  communityStatusMap,
  communityStatusOps,
  searchRegionTypeMap,
  searchRegionTypeOps,
  appTypeMap,
  appTypeOps,
  jumpGoCodeMap,
  hierarchyOps,
  hierarchyMap
}
