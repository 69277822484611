
// const butlerBaseURL = `http://192.168.10.86:3500/manage/`
// API_CONFIG.baseURL
/** 资讯 **/
// 获取资讯列表
const getInfomationListURL = `${API_CONFIG.butlerBaseURL}information/list`
// const getInfomationListURL = `${API_CONFIG.baseURL}infomationAction!list.action`
// 获取组织标签列表
const getOrgTagListURL = `${API_CONFIG.butlerBaseURL}orgTag/getDropDownSearchTag`
// 获取分类
const getRuleCodeLevelListURL = `${API_CONFIG.butlerBaseURL}knowledgeInfoType/getNormalRuleCodeLevel`
// const getRuleCodeLevelListURL = `${API_CONFIG.baseURL}serverCodewordAction!getNormalRuleCodeLevel.action`
// 资讯批量关闭操作
const batchInfomationURL = `${API_CONFIG.baseURL}infomationAction!deletes.action`
// 资讯关闭操作
const offInfomationURL = `${API_CONFIG.baseURL}infomationAction!delete.action`

// 新增
const createInfoURL = `${API_CONFIG.butlerBaseURL}information/add`
// const createInfoURL = `${API_CONFIG.baseURL}infomationAction!save.action`
// 编辑
const editInfoURL = `${API_CONFIG.butlerBaseURL}information/update`
// const editInfoURL = `${API_CONFIG.baseURL}infomationAction!update.action`
// 查询详情
const queryInfoURL = `${API_CONFIG.butlerBaseURL}information/getInformationDetail`
// const queryInfoURL = `${API_CONFIG.baseURL}infomationAction!edit.action`
// 上传
const uploadURL = `${API_CONFIG.uploadURL}?module=nei`
// 获取关联组织标签列表
const getOrgTagSearchListURL = `${API_CONFIG.butlerBaseURL}orgTag/commonOrgTagSearch?isPartyTag=0`
// 获取上级标签列表
const getSuperiorTagListURL = `${API_CONFIG.butlerBaseURL}orgTag/getSuperiorTagByTagName`
// 获取项目列表
const getCommunityListURL = `${API_CONFIG.controlBaseURL}community/getCommunityList`
// 获取运营项目组
const getOperateRegionsURL = `${API_CONFIG.baseURL}serverCodewordAction!getOperateRegions.action`
// 获取阶段状态
const getProjectTypesURL = `${API_CONFIG.baseURL}serverCodewordAction!getProjectTypes.action`
// 项目类型
const getRoomTypesURL = `${API_CONFIG.baseURL}serverCodewordAction!getRoomTypes.action`
// 检测园区是否可以跳转
const checkSkipCommunityURL = `${API_CONFIG.shopBaseURL}skipLifeShu/checkSkipCommunity`

/** 帖子 **/
// 获取帖子列表
const getPostListURL = `${API_CONFIG.baseURL}serverInteractionAction!list.action`
// 帖子置顶操作
const updateTopPostURL = `${API_CONFIG.baseURL}serverInteractionAction!updateTop.action`
// 新增帖子
const createPostURL = `${API_CONFIG.baseURL}serverInteractionAction!save.action`
// 编辑帖子
const editPostURL = `${API_CONFIG.baseURL}serverInteractionAction!update.action`
// 查询帖子详情
const queryPostURL = `${API_CONFIG.baseURL}serverInteractionAction!queryOne.action`
// 获取帖子话题列表
const getTopicListURL = `${API_CONFIG.butlerBaseURL}communitySubject/getTopicCommunitySubjectByCommunityId`

/** 分类 **/
// 获取分类列表
// const getCategoryListURL = `${API_CONFIG.butlerBaseURL}knowledgeInfoType`
const getCategoryListURL = `${API_CONFIG.butlerBaseURL}knowledgeInfoType`
// 编辑
const editCategoryURL = `${API_CONFIG.butlerBaseURL}knowledgeInfoType`
// 查询分类详情
const queryCategoryURL = `${API_CONFIG.butlerBaseURL}knowledgeInfoType`
// 获取上级分类列表
const getFirstLevelListURL = `${API_CONFIG.butlerBaseURL}knowledgeInfoType`
// 查询全部的一级标签
const getAllFirstLabel = `${API_CONFIG.butlerBaseURL}user/orgTag/current/user/superiorTag`

export {
  /** 资讯 **/
  getInfomationListURL,
  getOrgTagListURL,
  batchInfomationURL,
  offInfomationURL,
  getRuleCodeLevelListURL,
  createInfoURL,
  editInfoURL,
  queryInfoURL,
  uploadURL,
  getOrgTagSearchListURL,
  getSuperiorTagListURL,
  getCommunityListURL,
  getOperateRegionsURL,
  getProjectTypesURL,
  getRoomTypesURL,
  checkSkipCommunityURL,
  /** 帖子 **/
  getPostListURL,
  updateTopPostURL,
  createPostURL,
  editPostURL,
  queryPostURL,
  getTopicListURL,
  /** 分类 **/
  getCategoryListURL,
  editCategoryURL,
  queryCategoryURL,
  getFirstLevelListURL,
  getAllFirstLabel
}
